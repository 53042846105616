import React from "react";
import {Navigate, Outlet  } from "react-router-dom";

const RouteProtection = () =>{
    const instructor_id = localStorage.getItem("instructor_id");
    const token = sessionStorage.getItem('token');
    return(
        
        instructor_id && token?
                <Outlet/>
                :
                <Navigate to='/'/>

    )
};
export default RouteProtection;