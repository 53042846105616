import { Skeleton } from "@mui/material";
import React from "react";

const CoursesSkeleton =() =>{
    return(
        <div className=" grid grid-cols-1 px-5 mt-10 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40">
        {/* card 1 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 2 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 3 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 4 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 5 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 6 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 7 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 8 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 9 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
        {/* card 10 */}
        <div>
          <div style={{ borderRadius: '20px', height: "250px" }}>
            <Skeleton animation="wave" variant="rounded" height={250} />
          </div>
          <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
          <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
            <Skeleton animation="wave" variant="rounded" width={70} height={30} />
            <Skeleton animation="wave" variant="circular" width={30} height={30} />
          </div>
        </div>
      </div>
    )
};
export default CoursesSkeleton;