import React, { useEffect, useState } from 'react';
import './style.css';
import IconGamizignWorld from '../../../assets/icons/left panel icons/The Gamizign World off icon.png'
import IconGamizignWorldOn from '../../../assets/icons/left panel icons/HoverState/The Gamizign World on icon.png'
// Normal black State Sidebar Icons 
import IconNewActivity from '../../../assets/icons/left panel icons/NormalState/New Activity off icon.png'
import IconNewActivityOn from '../../../assets/icons/left panel icons/HoverState/New Activity on icon.png'
import IconQuestionList from '../../../assets/icons/left panel icons/NormalState/Question Lists off icon.png'
import IconQuestionListOn from '../../../assets/icons/left panel icons/HoverState/Question Lists on icon.png'
import IconGamizign from '../../../assets/icons/left panel icons/NormalState/Gamizign off icon.png'
import IconGamizignOn from '../../../assets/icons/left panel icons/HoverState/Gamizign on icon.png'
import IconInsights from '../../../assets/icons/left panel icons/NormalState/Insights icon off.png'
import IconInsightsOn from '../../../assets/icons/left panel icons/HoverState/Insights icon on.png'
// import IconInstructor from '../../../assets/icons/left panel icons/NormalState/Instructors icon off.png'
// import IconInstructorOn from '../../../assets/icons/left panel icons/HoverState/Instructors icon on .png'
import IconLearnerList from '../../../assets/icons/left panel icons/NormalState/Learner Lists icon off.png'
import IconLearnerListOn from '../../../assets/icons/left panel icons/HoverState/Learner Lists icon on.png'
// import IconUsers from '../../../assets/icons/left panel icons/NormalState/Users icon off.png'
// import IconUsersOn from '../../../assets/icons/left panel icons/HoverState/Users icon on.png'
// import IconRewards from '../../../assets/icons/left panel icons/NormalState/Rewards off icon.png'
// import IconRewardsOn from '../../../assets/icons/left panel icons/HoverState/Rewards icon on.png'
import IconSettings from '../../../assets/icons/left panel icons/NormalState/Settings icon off.png'
import IconSettingsOn from '../../../assets/icons/left panel icons/HoverState/Settings icon on.png'
// import IconHelp from '../../../assets/icons/left panel icons/NormalState/Help icon off.png'
// import IconHelpOn from '../../../assets/icons/left panel icons/HoverState/Help icon on.png'
import LogoPic from '../../../assets/images/Gamizign-logo.png'
// import Activity from './sidbarOptionsContent/Activity/activity';
// import NewCourse from './sidbarOptionsContent/Activity/Newcourse/newCourse';
import { Link, Outlet } from 'react-router-dom';
import { Box, Divider, Drawer, Hidden, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import OnboardingComponent from './sidbarOptionsContent/The Gamizign World/Components/Onboarding';
// import LoginHeading from '../../LoginHeading/LoginHeading';
import Googlelay from '../../../assets/images/Google-Play.png'
import AppleStore from '../../../assets/images/Apple-store.png'
import axiosInstance from '../../../axios';




const Dashboard = ({mode}) => {
  const Tab = parseInt(localStorage.getItem("tab"));
  const [selectedTab, setSelectedTab] = useState(Tab);
  const [selectedTabName, setSelectedTabName] = useState('The Gamizign World');
  const LearnersList = localStorage.getItem("LearnersList");
  const LicenceType = parseInt(localStorage.getItem("plan"));
  const [Onboarding, setOnboarding] = useState(parseInt(localStorage.getItem("accountActive")));
   
  const menuItems = [
    { id: 11, icon: IconGamizignWorld, iconOn: IconGamizignWorldOn, label: 'The Gamizign World', style: 'BgcolorOf4 font-bold text-xl', link: '/Dashboard' },
    { id: 1, icon: IconNewActivity, iconOn: IconNewActivityOn, label: 'New Activity', style: 'BgcolorOf4', link: '/Dashboard/CreateCourse' },
    { id: 2, icon: IconQuestionList, iconOn: IconQuestionListOn, label: 'Question Lists', style: 'BgcolorOf4', link: '/Dashboard/questionList' },
    { id: 3, icon: IconGamizign, iconOn: IconGamizignOn, label: 'Gamizign', style: 'BgcolorOf4', link: '/Dashboard/Courses' },
    { id: 4, icon: IconInsights, iconOn: IconInsightsOn, label: 'Insights', style: '', link: '/Dashboard/insights' },
    // { id: 5, icon: IconInstructor, iconOn: IconInstructorOn, label: 'Instructors', style: '', link: '/Dashboard/instructors' },
    { id: 6, icon: IconLearnerList, iconOn: IconLearnerListOn, label: 'Learner List', style: '', link: '/Dashboard/learnerList' },
    // { id: 6, icon: IconLearnerList, iconOn: IconLearnerListOn, label: 'Students/Classes', style: '', link: '/AddStudents' },
    // { id: 7, icon: IconUsers, iconOn: IconUsersOn, label: 'Users', style: '', link: '/Dashboard/users' },
    // { id: 8, icon: IconRewards, iconOn: IconRewardsOn, label: 'Rewards', style: '', link: '/Dashboard/rewards' },
    { id: 9, icon: IconSettings, iconOn: IconSettingsOn, label: 'Settings', style: '', link: '/Dashboard/settings' },
    // { id: 10, icon: IconHelp, iconOn: IconHelpOn, label: 'Help', style: '', link: '/Dashboard/help' },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleTabClick = (tabId, tabLabel) => {
    setSelectedTab(tabId);
     localStorage.setItem("tab", tabId);
    // setSelectedTabName(tabLabel);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawerWidth = 240;
  const drawer = (
    <div>
      {mobileOpen && 
      <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 64,
        backgroundColor: 'primary.main',
        color: 'white',
        fontSize: 20,
      }}
      >
        <img src={LogoPic} alt="Logo" style={{ height: '100%' }} />
      </Box>
      }
      <Divider />
      <List sx={{ padding: '0px' }}>
        {menuItems.map((item, index) => (
          <Link className={`${item.id === 6 && LearnersList === 'hide' ? 'hidden' : '' } ${item.id === 11 && LicenceType !== 3 ? 'hidden' : ''}`} id={`step-${index +1}`} to={item.link}>
            <ListItem 
              button
              key={item.text}
              selected={selectedTab === item.id}
              onClick={(event) => { handleTabClick(item.id, event, index); setMobileOpen(false) }}
            >
              <ListItemIcon
              >
                <img src={mode === 'light' ?  item.icon : item.iconOn} alt="" />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem><Divider /></Link>
        ))}
      </List>
      <Divider />
      <Box
        component="div"
        sx={{
          display: '',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '10px'
        }}
      >
        {/* {!mobileOpen && <img src={LogoPic} alt="Logo" className='w-20 h-20' />} */}
         <div className='flex my-2 justify-center sm:justify-between'>
          {!mobileOpen &&<img src={LogoPic} alt="Logo" className='w-20 h-20' />}
          <div className='me-5'>
            <div><Link target="_blank" to='http://bit.ly/3Tw8PDV'><img className='' src={Googlelay} alt="" /></Link></div>
            <div><Link target="_blank" to='https://apple.co/49Pc7I6'><img className='' src={AppleStore} alt="" /></Link></div>
          </div>
        </div>
        <Link to='https://gamizign.com/privacy-policy/'><p className={`font-bold ${mobileOpen && 'mt-5'}`}>Privacy Policy & Terms of Use</p></Link>
        <p>Subscription Agreement</p>
      </Box>
    </div>

  );
  // get classes and manage WebGl flow
  const [classes, setClasses] = useState(null);
  const [loading, setLoading] = useState(true);
  const getClasses = async () => {
    setLoading(true);
    var org = localStorage.getItem("organization");
    const resp = await axiosInstance.get(`${process.env.REACT_APP_ENDPOINT}/organization/${org}/classes/`);
    setClasses(resp.data.classes);
    setLoading(false);
};
useEffect(() => {
    getClasses();
}, [])

  return (
    <div>
      <div className="flex">
      {/* {LearnersList === 'show' && !loading && classes[0].students.length === 1 && <OnboardingComponent/>} */}
        {/* Sidebar Tabs*/}
        <Box
          component="nav"
          sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
          aria-label="mailbox folders"
        >
          <Hidden smUp implementation="css">
            <Drawer
              labelStyle={{fontFamily: 'Cairo_Regular'}}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', lg: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', lg: 'block' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              // open
              open={!mobileOpen}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </Box>
        <div className="SIDEMENU flex-none FixedWidth256 hidden">
          <div className="SidbarBgColor textcolor">
            <div className="mb-8">
              {/* <div className='flex py-2 px-4 BgcolorOf4'><img className='mr-2' src={IconGamizignWorld} alt="" /><h3 className='font-bold text-xl'>The Gamizign World</h3>
              </div> */}
              {menuItems.map((item) => (
                <div className={item.style}>
                  <Link to={item.link}> <div
                    key={item.id}
                    className={`flex items-center py-2 px-4 border cursor-pointer hover:bg-blue-100 ${selectedTab === item.id ? 'SlectTabColor' : ''}`}
                    onClick={() => handleTabClick(item.id, item.label)}>
                    <div className="mr-2"><img src={selectedTab === item.id ? item.iconOn : item.icon} alt="" /></div>
                    <span>{item.label}</span>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className=" justify-center items-center">
              {/*Sidebar Footer */}
              <div className='ml-4 mb-7'>
                <img src={LogoPic} alt="Logo" className="w-20 h-20 mr-2" />
                <Link to='https://gamizign.com/privacy-policy/'><p className='font-bold'>Privacy Policy & Terms of Use</p></Link>
                <p>Subscription Agreement</p>
              </div>

            </div>
          </div>
        </div>
        <div className='lg:hidden z-10 absolute top-0 right-0 px-3 py-3' onClick={handleDrawerToggle}>
          {!mobileOpen ? 
          <div>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
            <span className="block h-2 w-8"></span>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
            <span className="block h-2 w-8"></span>
            <span className="block h-0.5 w-8 bg-gray-600"></span>
          </div>
            :
          <div>
            <svg
              className="h-8 w-8 text-gray-600"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              >
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </div>
            }
        </div>

        <div className='MAINMENU flex-1'>
          <Outlet></Outlet>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;