import { Skeleton } from "@mui/material";
import React from "react";

const EditQuestionListSkeleton = () => {
    return (
        <div>
            {/* Question row/card */}
            <div>
                <div className="mt-7 flex justify-end  gap-2">
                    <div>
                        <div className="flex justify-end gap-2">
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                        </div>
                        <div className="flex justify-end mt-2">
                            <div>
                                <Skeleton animation="wave" variant="rounded" width={130} height={10} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <Skeleton animation="wave" variant="rounded" width={50} height={50} />
                    </div>
                </div>
                <div className="sm:ms-7 flex gap-1 sm:gap-3 ">
                    <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    <Skeleton animation="wave" variant="rounded" width='100%' height={70} />
                </div>
            </div>
            <div>
                <div className="mt-7 flex justify-end  gap-2">
                    <div>
                        <div className="flex justify-end gap-2">
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                        </div>
                        <div className="flex justify-end mt-2">
                            <div>
                                <Skeleton animation="wave" variant="rounded" width={130} height={10} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <Skeleton animation="wave" variant="rounded" width={50} height={50} />
                    </div>
                </div>
                <div className="sm:ms-7 flex gap-1 sm:gap-3 ">
                    <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    <Skeleton animation="wave" variant="rounded" width='100%' height={70} />
                </div>
            </div>
            <div>
                <div className="mt-7 flex justify-end  gap-2">
                    <div>
                        <div className="flex justify-end gap-2">
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                        </div>
                        <div className="flex justify-end mt-2">
                            <div>
                                <Skeleton animation="wave" variant="rounded" width={130} height={10} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <Skeleton animation="wave" variant="rounded" width={50} height={50} />
                    </div>
                </div>
                <div className="sm:ms-7 flex gap-1 sm:gap-3 ">
                    <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    <Skeleton animation="wave" variant="rounded" width='100%' height={70} />
                </div>
            </div>
            <div>
                <div className="mt-7 flex justify-end  gap-2">
                    <div>
                        <div className="flex justify-end gap-2">
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                        </div>
                        <div className="flex justify-end mt-2">
                            <div>
                                <Skeleton animation="wave" variant="rounded" width={130} height={10} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <Skeleton animation="wave" variant="rounded" width={50} height={50} />
                    </div>
                </div>
                <div className="sm:ms-7 flex gap-1 sm:gap-3 ">
                    <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    <Skeleton animation="wave" variant="rounded" width='100%' height={70} />
                </div>
            </div>
            <div>
                <div className="mt-7 flex justify-end  gap-2">
                    <div>
                        <div className="flex justify-end gap-2">
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                            <Skeleton animation="wave" variant="rounded" width={40} height={20} />
                        </div>
                        <div className="flex justify-end mt-2">
                            <div>
                                <Skeleton animation="wave" variant="rounded" width={130} height={10} />
                            </div>
                        </div>
                    </div>
                    <div className="mb-2">
                        <Skeleton animation="wave" variant="rounded" width={50} height={50} />
                    </div>
                </div>
                <div className="sm:ms-7 flex gap-1 sm:gap-3 ">
                    <Skeleton animation="wave" variant="circular" width={60} height={60} />
                    <Skeleton animation="wave" variant="rounded" width='100%' height={70} />
                </div>
            </div>
        </div>
    )
};
export default EditQuestionListSkeleton;