import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_ENDPOINT,
//   baseURL: 'http://15.237.2.17:8000',
});

// Set common headers
axiosInstance.defaults.headers.common['Authorization'] = `${sessionStorage.getItem('token')}`;
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.put['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.delete['Content-Type'] = 'application/json';
axiosInstance.postForm['Content-Type'] = 'multipart/form-data'; 


export default axiosInstance;
